class Accordion {
    element = jQuery('.js-accordion');
    items: any;
  constructor() {
    this.items = this.element.find('.js-accordion__item');
    let openItems;
    this.items.each((index, item) => {
        let items = $(item);
        let openItems = new AccordionItem(items);
    });

  }
}

class AccordionItem {
  element: any;
  constructor(items) {
    this.element = items;
    jQuery('.js-accordion__item').find(".is-less").hide();
    this.element.find(".js-accordion__item__trigger").on('click', this.Toggle.bind(this))
  }

  Toggle(e) {
    if(this.element.hasClass("is-open")) {
      jQuery('.js-accordion__item').removeClass("is-open");
      jQuery('.js-accordion__item').find(".is-less").hide();
      jQuery('.js-accordion__item').find(".is-more").show();
    }else {
      jQuery('.js-accordion__item').removeClass("is-open");
      this.element.addClass('is-open');
      this.element.find(".is-less").show();
        this.element.find(".is-more").hide();
    }

    // this.element.addClass('is-open');
    // this.element.find(".is-less").show();
    //
    //
    // if(this.count == 2) {
    //   this.element.removeClass('is-open');
    //   this.element.find(".is-more").show();
    //   this.element.find(".is-less").hide();
    //   this.count = 0;
    // }
    // this.count++;
    // console.log(this.count, this.element);
  }
}


let accordion = new Accordion();
