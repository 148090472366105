
import "./menu";
// import "./fade";
import "./loadMore";
import "./faq";
import  "./lightbox";
import "./anchor";
import "./popup";
import "./smoelenboek";
import "./accordion";
