class Menu {
    menu: any;
    constructor() {
        $('.c-nav-toggle').on('click', this.toggle.bind(this));
        $('main').on('click', this.destroy.bind(this));
        this.swiperPosition();
        // this.height();
        $(window).resize(this.destroy.bind(this));
        // $(window).resize(this.height.bind(this));
        $(window).scroll(this.scroll.bind(this));
        $(window).resize(this.swiperPosition.bind(this));
    }
    toggle(event){
        event.preventDefault();
        $('body').toggleClass('is-activeNav');

    }
    destroy(event) {
        if ($('.is-activeNav').length) {
            $('body').removeClass('is-activeNav');
        }
    }

    scroll() {
       if($(window).scrollTop() >= 1)    {
          $('body').addClass('is-scrolled')
          $('.c-nav-controls').addClass('c-nav-controls--bg')
          $('nav').find(".is-12-desktop").removeClass("is-12-desktop").addClass("is-11-desktop");
          $('nav').find(".is-2-desktop").removeClass("is-2-desktop").addClass("is-1-desktop");
       }else {
         $('.c-nav-controls').removeClass('c-nav-controls--bg')
         $('body').removeClass('is-scrolled')
         $('nav').find(".is-11-desktop").removeClass("is-11-desktop").addClass("is-12-desktop");
         $('nav').find(".is-1-desktop").removeClass("is-1-desktop").addClass("is-2-desktop");
       }
    }

    height() {
      let menu = jQuery('nav').outerHeight() + 30;
    	  if ($(window).width() >= 900) {
            jQuery('body').css({
                "padding-top": `${menu}px`
            });
        	if(jQuery(".home").length) {
        		jQuery("#wrapper").css({
                "margin-top": `-${menu}px`
            });
      	}
      }
    }


    swiperPosition() {
      let marginRight = ($(window).outerWidth() - $(".js-width").innerWidth()) / 2;
      $('.swiper').css({"margin-right": `-${marginRight}px`})

      if ($(window).width() >= 821) {
        $('.swiper').css({"margin-right": `-${marginRight}px`})

      } else if ($(window).width() <= 820) {
        $('.swiper').css({"margin-right": `0`})
      };
    }


}
let menu = new Menu();
