class Faq {
    element = $('.js-faq');
    items: any;
    topics: any;
  constructor() {
    $('article').hide();
    $('article').first().show();
    $('.js-faq--topic').first().addClass('active');
    $('.js-accessibility').hide();
    this.topics = this.element.find('.js-faq--topics a');
    this.items = this.element.find('.js-item');
    let openItems;
    let openTopics;
    this.items.each((index, item) => {
        let items = $(item);
        let openItems = new FaqItems(items);
    });
    this.topics.each((index, item) => {
        let topic = $(item);
        let openTopics = new FaqTopics(topic);
    });
    $('#'+$('.js-faq--topic.active').attr('aria-controls')).find('.js-item').first().addClass('active');
  }
}

class FaqTopics {
  element: any;
  constructor(topic) {
    this.element = topic;
    this.element.on('click', this.open.bind(this));

  }

  open(event) {
    event.preventDefault();
    let tab = '#' + this.element.attr('aria-controls');
    $('article').hide();
    $(tab).show();
    if(this.element.hasClass('active')) {
      this.element.removeClass('active');
    }else {
        $('.js-faq--topic.active').removeClass('active');
        this.element.addClass('active');
    }
  }

}

class FaqItems {
    element: any;
    content: any;

    constructor(items) {
        this.element = items;
        this.content = this.element.find('dd');
        this.element.on('mouseup', this.toggle.bind(this));
        this.element.removeClass('active');
    }
    toggle() {
        if(this.element.hasClass('active')) {
          this.element.removeClass('active');
        }else {
            $('.js-item.active').removeClass('active');
            this.element.addClass('active');
        }
    }

    close() {

    }

}

let faq = new Faq();
