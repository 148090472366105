class Overlay {
    element = jQuery('.js-smoelenboek');
    items: any;
  constructor() {
    this.items = this.element.find('.js-person');
    let openItems;
    this.items.each((index, item) => {
        let items = $(item);
       let openItems = new Personen(items);
    });

  }
}

class Personen {
  element: any;
  constructor(items) {
    this.element = items;
    this.element.on('click', this.Open.bind(this))
  }

  Open() {
      let overlayImgSrc = this.element.attr('data-img');
      console.log(overlayImgSrc)
      jQuery(".js-overlay").show();
      jQuery(".js-overlay").find("img").attr('src', overlayImgSrc);
      jQuery(".js-overlay").find(".js-functie").append(this.element.attr('data-functie'));
      jQuery(".js-overlay").find(".js-naam").append(this.element.attr('data-name'));
      jQuery(".js-overlay").find(".js-info").append(this.element.attr('data-info'));

      jQuery(".js-overlay").find(".js-overlayClose").on('click', function() {
        jQuery(".js-overlay").hide();
        jQuery(".js-overlay").find("img").attr('src', overlayImgSrc);
        jQuery(".js-overlay").find(".js-functie").empty();
        jQuery(".js-overlay").find(".js-naam").empty();
        jQuery(".js-overlay").find(".js-info").empty();
      })
  }


}


let overlay = new Overlay();
